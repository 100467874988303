/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Black";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-BlackItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-BlackItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-BoldItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraLightItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Italic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-LightItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-LightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-MediumItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBoldItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Thin";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ThinItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ThinItalic.ttf) format("truetype");
}

* {
  font-family: 'Montserrat';
}

.paginationbullets {
  bottom: 0px;
  position: relative;
  margin-top: 20px;
}

.swiper-pagination-bullet {
  margin: 0 2px;
}

.radio-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.toast-custom-class {
  --start: 0px !important;
  --end: 0px !important;
  --bottom: 0px !important;
  --background: #FFFFFF;
  --color: #262020;
  --max-width: none;
  --button-color: #00000;
  --height: 80px;
}

.toast-custom-class {
  &::part(toast-wrapper) {
    bottom: 0px !important;
  }
}

.toast-button {
  background-color: #262020;
  color: #ffffff;
  height: 33px;
  border-radius: 25px;
  width: 94%;
  margin: 4px;
}


ion-radio {
  --border-radius: 4px;
  --inner-border-radius: 4px;

  --color: #ddd;
  --color-checked: #6815ec;
}

ion-radio.ios::part(container) {
  width: 20px;
  height: 20px;

  border: 2px solid #ddd;
  border-radius: 4px;
}

.radio-checked.ios::part(container) {
  border-color: #6815ec;
}

.small-modal {
  &::part(backdrop) {
    background-color: black;
  }

  &::part(content) {
    width: 80%;
    height: 30%;
    margin: auto;
    border-radius: 10px;
  }
}

.medium-modal {
  &::part(backdrop) {
    background-color: black;
  }

  &::part(content) {
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 0;
    // border-radius: 0px 0px 20px 20px;
    /* Top-left and top-right radius is 10px, bottom-left and bottom-right radius is 0 */
    overflow: hidden;
  }
}

.profile-modal {
  &::part(backdrop) {
    background-color: black;
  }

  &::part(content) {
    width: 90%;
    height: 70%;
    margin: auto;
    border-radius: 10px;
  }
}
.user-login {
  bottom: 0;
  min-height: initial;
  top: initial;
  height: 70%;
  --backdrop-opacity: 0.5;
}

.user-login {&::part(backdrop) { 
  background-color: rgba(209, 213, 219);
  opacity: 1;
}
}

.calendar-modal {
  bottom: 0;
  min-height: initial;
  top: initial;
  height: 75%;
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  --backdrop-opacity: 0;
}

.filter-modal {
  bottom: 0;
  top: initial;
  --backdrop-opacity: 0;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  height: 75%;
}


.calendar-modal {
  &::part(backdrop) {
    background-color: black;
  }
}

.uploadID-modal {
  &::part(backdrop) {
    background-color: black;
  }

  &::part(content) {
    width: 90%;
    height: 50%;
    margin: auto;
    border-radius: 10px;
  }
}

.success-modal {
  --max-height: 25%;
  --min-height: 25%;
}

.success-modal {
  background: rgba(173, 167, 167, 0.5);

  &::part(backdrop) {
    background-color: black;
  }

  &::part(content) {
    height: 20%;
  }

  &::part(content) {
    width: 90%;
    height: 50%;
    margin: auto;
    border-radius: 10px;
  }
}

.success-popup {
  &::part(backdrop) {
    background-color: black;
  }

  &::part(content) {
    width: 90%;
    height: 50%;
    margin: auto;
    border-radius: 10px;
  }
}

/* Toast message styling*/

.success {
  --background: #4BB543;
  --color: #ffffff;
}

.error {
  --background: #D42F2F;
  --color: #ffffff;
}

.warning {
  --background: #FFC700;
  --color: #ffffff;
}

/* Loader Styling*/
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(173, 167, 167, 0.5);
  z-index: 999;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 10px;
  padding: 30px 40px;
}

.spinner {
  width: 50px;
  height: 50px;
  display: inline-block;
  border-width: 4px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #000000;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}